<template>
  <div>
    <v-row>
      <v-col cols="12">
        <FtManagementForm ref="FtMngForm" @search="search"></FtManagementForm>
      </v-col>
      <v-col cols="12">
        <!-- Tableau -->
        <v-data-table
          v-model="selected"
          :headers="headers"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
            'rows-per-page-text': $t('linePage')
          }"
          :server-items-length.sync="listFt.total"
          :items="listFt.ficheTechnique"
          item-key="ficheTechId"
          show-select
          dense
          class="elevation-1 list-article with-datatable"
        >
          <template #[`item.titre`]="{ item }">
            <span v-if="item.titre != ''">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="truncated titreTable">
                    {{ item.titre }}
                  </span>
                </template>
                <span>{{ item.titre }}</span>
              </v-tooltip>
            </span>
          </template>
          <template #[`item.articleName`]="{ item }">
            <span v-if="item.articleName != ''">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="truncated articleTable">
                    {{ item.articleName }}({{ item.codeArticle }})
                  </span>
                </template>
                <span>{{ item.articleName }}({{ item.codeArticle }})</span>
              </v-tooltip>
            </span>
          </template>
          <template #[`item.fournisseurName`]="{ item }">
            <span v-if="item.fournisseurName != ''">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="truncated fournisseurTable">
                    {{ item.fournisseurName }}
                  </span>
                </template>
                <span>
                  {{ item.fournisseurName }}
                </span>
              </v-tooltip>
            </span>
          </template>
          <template #[`item.articleActif`]="{ item }">
            <v-icon
              :class="
                item.articleActif == 1 && item.articleDeleted == 0
                  ? 'green--text'
                  : 'red--text'
              "
              >{{
                item.articleActif == 1 && item.articleDeleted == 0
                  ? "mdi-checkbox-marked-circle-outline"
                  : "mdi-alert-circle-outline"
              }}</v-icon
            >
          </template>
          <template #[`item.modification`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon color="green lighten-1" v-if="modif(item)">
                    mdi-checkbox-multiple-marked-outline
                  </v-icon>
                  <v-icon color="lighten-1" v-else>
                    mdi-checkbox-multiple-blank-outline
                  </v-icon>
                </span>
              </template>
              <div v-for="modif in listModification" :key="modif.id">
                <p>
                  <v-icon color="white" v-if="item[modif.id] != false">
                    mdi-checkbox-marked-outline
                  </v-icon>
                  <v-icon color="white" v-else>
                    mdi-checkbox-blank-outline
                  </v-icon>
                  {{ modif.name }}
                </p>
              </div>
            </v-tooltip>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              small
              fab
              icon
              class="blue--text ma-1"
              @click.native="exporter(item.ficheTechId)"
              :title="$t('button.download')"
            >
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-btn>
            <v-btn
              small
              fab
              icon
              class="blue--text"
              @click.native="
                showFtInfo(
                  item.codeArticle,
                  item.codeFournisseur,
                  item.ficheTechId,
                  item.isFta
                )
              "
              :title="$t('button.articleDetail')"
            >
              <v-icon>mdi-folder-outline</v-icon>
            </v-btn>
            <v-btn
              small
              fab
              icon
              class="red--text"
              v-if="item.isDeleted"
              @click.native="purgeArticle(item.articleId)"
              :title="$t('button.delete')"
            >
              <v-icon>mdi-delete-forever-outline</v-icon>
            </v-btn>
          </template>

          <template slot="pageText" slot-scope="props">
            {{
              $t("lineOf", {
                pageStart: props.pageStart,
                pageStop: props.pageStop,
                itemsLength: props.itemsLength
              })
            }}
          </template>
          <template slot="no-data">
            &nbsp;
            <v-alert
              v-if="listFt.total === 0"
              :value="true"
              color="error"
              icon="mdi-alert-outline"
              >{{ $t("noData") }}</v-alert
            >
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              color="error"
              class="d-inline"
              v-if="userDroit === 5"
              v-on:click="purgeArticle()"
            >
              {{ $t("button.deleteAll") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              class="d-inline"
              v-if="userDroit >= 1"
              v-on:click="exportFTA"
            >
              {{ $t("button.exportAll") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              class="d-inline"
              v-if="userDroit >= 1"
              v-on:click="exportZipFt"
            >
              {{ $t("button.exportZip") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ArticleManagementDialogDetailFT
      v-if="dialogDetail"
      :dialog.sync="dialogDetail"
      :articleId="articleId"
      :fournisseurId="fournisseurId"
      :ficheTechId="ficheTechId"
      :isFta="isFta"
      @refresh="search"
    ></ArticleManagementDialogDetailFT>
    <v-dialog v-model="dialogLoader" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loaderMessage") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import { getURLApi } from "../utils";
import { mapState, mapActions } from "vuex";
import FtManagementForm from "@/components/FicheTechniqueManagementForm";
import ArticleManagementDialogDetailFT from "@/components/ArticleManagementDialogDetailFT";
export default {
  created() {
    this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
    if (this.idFt != "0") {
      this.exporter(this.idFt);
    }
  },
  props: {
    idFt: { type: String, required: false, default: "0" }
  },
  data() {
    return {
      userDroit: 0,
      articleId: 0,
      fournisseurId: 0,
      ficheTechId: 0,
      isFta: false,
      returnFile: [],
      //dialog
      dialogDetail: false,
      dialogLoader: false,
      //Donnée Data table
      oldSearch: {},
      loading: false,
      rowsPerPageItems: [50],
      options: {},
      selected: [],
      listModification: [
        {
          name: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.chkChangeAllergen"
          ),
          id: "modificationAllergene"
        },
        {
          name: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.chkChangeNutritionalValues"
          ),
          id: "modificationValeurNutri"
        },
        {
          name: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.chkChangeIngredient"
          ),
          id: "modificationIngredient"
        },
        {
          name: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.chkChangeLogisticsCondition"
          ),
          id: "modificationConditionsLogi"
        },
        {
          name: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.chkChangeCodeEAN"
          ),
          id: "modificationCodeEan"
        },
        {
          name: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.ChangeOther"
          ),
          id: "modificationAutre"
        }
      ],
      headers: [
        {
          text: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.articleCode"
          ),
          align: "left",
          value: "codeArticle"
        },
        {
          text: this.$t("headers.ficheTechniqueManagement.ftManagement.name"),
          align: "left",
          value: "nom"
        },
        {
          text: this.$t("headers.ficheTechniqueManagement.ftManagement.title"),
          value: "titre"
        },
        {
          text: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.articleName"
          ),
          align: "left",
          value: "articleName"
        },
        {
          text: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.providerName"
          ),
          align: "left",
          value: "fournisseurName"
        },
        {
          text: this.$t("headers.ficheTechniqueManagement.ftManagement.active"),
          align: "center",
          value: "articleActif"
        },
        {
          text: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.modifications"
          ),
          align: "center",
          value: "modification"
        },
        {
          text: this.$t(
            "headers.ficheTechniqueManagement.ftManagement.DateUpdate"
          ),
          align: "center",
          value: "dateMaj"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "left",
          width: "120px",
          class: "min-width-action"
        }
      ]
    };
  },
  computed: {
    ...mapState("FtManagement", ["listFt"]),
    ...mapState("user", ["userInfo", "token"])
  },

  methods: {
    ...mapActions("FtManagement", ["fetchListFt", "getZipFile"]),
    ...mapActions("user", ["getUserInfo"]),
    search(val = null) {
      this.loading = true;
      if (val != null) {
        this.oldSearch = val;
        this.options.page = 1;
      } else {
        val = this.oldSearch;
      }
      this.fetchListFt({
        codeArticle: val.codeArticle,
        labelArticle: val.labelArticle,
        famille: val.famille ? val.famille.join() : "",
        fournisseurs: val.fournisseurs ? val.fournisseurs.join() : "",
        certificats: val.certificats ? val.certificats.join() : "",
        filiere: val.filiere ? val.filiere.join() : "",
        temperature: val.temperature ? val.temperature.join() : "",
        paysProduction: val.paysProduction ? val.paysProduction.join() : "",
        departementOrigine: val.departementOrigine
          ? val.departementOrigine.join()
          : "",
        industriel: val.industriel ? val.industriel.join() : "",
        marque: val.marque ? val.marque.join() : "",
        fabricant: val.fabricant ? val.fabricant.join() : "",
        pleinText: val.pleinText,
        showDeleted: val.showDeleted,
        dateDebut: val.dateDebut,
        dateFin: val.dateFin,
        page: this.options.page,
        rowNumber: this.options.itemsPerPage,
        multiSort: this.options.multiSort,
        sortBy: this.options.sortBy.join(),
        sortDesc: this.options.sortDesc.join()
      }).then(res => {
        this.loading = false;
      });
    },
    exporter(ficheTechId) {
      window.open(getURLApi() + "getFta.cfm?ftaId=" + ficheTechId);
    },
    formatDate(val) {
      if (!val) return;
      return this.$d(new Date(val), "formatDate");
    },
    modif(item) {
      return (
        item.modificationIngredient ||
        item.modificationAllergene ||
        item.modificationValeurNutri ||
        item.modificationConditionsLogi ||
        item.modificationCodeEan ||
        item.modificationAutre != ""
      );
    },
    showFtInfo(articleId, fournisseurId, ficheTechId, isFta) {
      this.articleId = articleId;
      this.fournisseurId = fournisseurId;
      this.ficheTechId = ficheTechId;
      this.isFta = isFta;
      this.dialogDetail = true;
    },
    refreshArticles() {
      this.dialogDetail = false;
      this.search();
    },

    exportFTA() {
      axios
        .post(
          getURLApi() + "cfcs/listeArticles.cfc?method=exportFta",
          qs.stringify({
            param: JSON.stringify({
              codeArticle: this.$refs.FtMngForm.formData.codeArticle,
              labelArticle: this.$refs.FtMngForm.formData.labelArticle,
              famille: this.$refs.FtMngForm.formData.famille.join(),
              fournisseurs: this.$refs.FtMngForm.formData.fournisseurs.join(),
              certificats: this.$refs.FtMngForm.formData.certificats.join(),
              filiere: this.$refs.FtMngForm.formData.filiere.join(),
              temperature: this.$refs.FtMngForm.formData.temperature.join(),
              paysProduction: this.$refs.FtMngForm.formData.paysProduction.join(),
              departementOrigine: this.$refs.FtMngForm.formData.departementOrigine.join(),
              industriel: this.$refs.FtMngForm.formData.industriel.join(),
              marque: this.$refs.FtMngForm.formData.marque.join(),
              fabricant: this.$refs.FtMngForm.formData.fabricant.join(),
              pleinText: this.$refs.FtMngForm.formData.pleinText,
              showDeleted: this.$refs.FtMngForm.formData.showDeleted,
              dateDebut: this.$refs.FtMngForm.formData.dateDebut,
              dateFin: this.$refs.FtMngForm.formData.dateFin
            }),
            articleId: this.selected.map(r => r.codeArticle).join()
          }),
          {
            headers: {
              Authorization: this.token
            }
          }
        )
        .then(res => {
          let url =
            getURLApi() + "cfm/extractionFTA.cfm?xlsFile=" + res.data.xlsFile;
          window.open(url);
        });
    },
    exportZipFt() {
      this.getZipFile({
        listFt: this.selected.map(r => r.ficheTechId).join()
      }).then(res => {
        let url = getURLApi() + "exportZip.cfm?zipFile=" + res.file;
        window.open(url);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  },
  components: {
    FtManagementForm,
    ArticleManagementDialogDetailFT
  }
};
</script>

<style scoped>
.titreTable {
  width: 300px !important;
}
.articleTable {
  width: 200px !important;
}
.fournisseurTable {
  width: 200px !important;
}
.truncated {
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-article >>> .v-datatable th:last-child,
.min-width-action {
  min-width: 120px !important;
  max-width: 120px !important;
}
li.v-expansion-panel__container {
  background: inherit !important;
}

.with-datatable {
  width: calc(100vw - 48px);
}

@media only screen and (max-width: 959px) {
  .with-datatable {
    width: calc(100vw - 32px);
  }
}
</style>
